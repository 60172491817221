var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b({ staticClass: "w-100" }, "div", attrs, false),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.factoryResetDialog,
        callback: function ($$v) {
          _vm.factoryResetDialog = $$v
        },
        expression: "factoryResetDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Factory reset")]),
          !_vm.failure
            ? _c(
                "v-card-text",
                { staticClass: "pt-3" },
                [
                  _c("v-alert", { attrs: { outlined: "", type: "error" } }, [
                    _c("p", [
                      _vm._v(
                        " Factory reset will stop this application for all users and clear all data installed into the application's "
                      ),
                      _c("b", [_vm._v("LIB")]),
                      _vm._v(" area. "),
                    ]),
                    _vm._v(" These are typically: "),
                    _c("ul", [
                      _c("li", [
                        _vm._v("software packages (Python, R, LaTeX, etc.)"),
                      ]),
                      _c("li", [_vm._v("applicaton-level configurations")]),
                      _c("li", [
                        _vm._v(
                          "applicaton add-on data (e.g. database files for MariaDB or Redis add-ons)"
                        ),
                      ]),
                    ]),
                  ]),
                  _c("v-alert", { attrs: { outlined: "", type: "info" } }, [
                    _vm._v(" Factory reset "),
                    _c("b", [_vm._v("WILL NOT")]),
                    _vm._v(" affect the following files: "),
                    _c("ul", [
                      _c("li", [
                        _vm._v(
                          "application configuration and data files in your HOME are (Linux home ~)"
                        ),
                      ]),
                      _c("li", [
                        _vm._v("data in you FILES area (Workspace files)"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "v-card",
                    { attrs: { flat: "", outlined: "" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "ma-0 pa-4",
                        attrs: {
                          color: "primary",
                          label: "Take a snapshot before Factory reset",
                          "hide-details": "",
                          disabled: _vm.resettingApp,
                        },
                        model: {
                          value: _vm.autobackUp,
                          callback: function ($$v) {
                            _vm.autobackUp = $$v
                          },
                          expression: "autobackUp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card-text",
                [
                  _vm.failure
                    ? _c("dashboard-error", {
                        attrs: {
                          msg: "Something went wrong, please try again later!",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.resettingApp },
                  on: {
                    click: function ($event) {
                      _vm.factoryResetDialog = false
                    },
                  },
                },
                [_vm._v("Back")]
              ),
              !_vm.success && !_vm.failure
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        loading: _vm.resettingApp,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.resetApp()
                        },
                      },
                    },
                    [_vm._v("Reset")]
                  )
                : _vm._e(),
              _vm.success
                ? _c(
                    "v-btn",
                    { attrs: { color: "success", text: "" } },
                    [_c("v-icon", [_vm._v("mdi-check")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }